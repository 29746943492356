

#wrapper {
  display: flex;
}

#wrapper #content-wrapper {
  background-color: #F0F0F5;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}

.border-left-primary {
  border-left: .25rem solid #4e73df!important
}

.border-bottom-primary {
  border-bottom: .25rem solid #4e73df!important
}
.border-top-primary {
  border-top: .25rem solid #4e73df!important
}

.border-left-secondary {
  border-left: .25rem solid #858796!important
}

.border-bottom-secondary {
  border-bottom: .25rem solid #858796!important
}

.border-left-success {
  border-left: .25rem solid #1cc88a!important
}

.border-bottom-success {
  border-bottom: .25rem solid #1cc88a!important
}

.border-left-info {
  border-left: .25rem solid #36b9cc!important
}

.border-bottom-info {
  border-bottom: .25rem solid #36b9cc!important
}

.border-left-warning {
  border-left: .25rem solid #f6c23e!important
}

.border-bottom-warning {
  border-bottom: .25rem solid #f6c23e!important
}

.border-left-danger {
  border-left: .25rem solid #e74a3b!important
}

.border-bottom-danger {
  border-bottom: .25rem solid #e74a3b!important
}

.border-left-light {
  border-left: .25rem solid #f8f9fc!important
}

.border-bottom-light {
  border-bottom: .25rem solid #f8f9fc!important
}

.border-left-dark {
  border-left: .25rem solid #5a5c69!important
}

.border-bottom-dark {
  border-bottom: .25rem solid #5a5c69!important
}

.text-xs {
  font-size: .7rem
}


.text-capitalize {
  text-transform: capitalize!important
}

.font-weight-light {
  font-weight: 300!important
}

.font-weight-lighter {
  font-weight: lighter!important
}

.font-weight-normal {
  font-weight: 400!important
}

.font-weight-bold {
  font-weight: 700!important
}

.font-weight-bolder {
  font-weight: bolder!important
}

.font-italic {
  font-style: italic!important
}

.text-white {
  color: #fff!important
}

.text-primary {
  color: #4e73df!important
}

a.text-primary:focus,a.text-primary:hover {
  color: #224abe!important
}

.text-secondary {
  color: #858796!important
}

a.text-secondary:focus,a.text-secondary:hover {
  color: #60616f!important
}

.text-success {
  color: #1cc88a!important
}

a.text-success:focus,a.text-success:hover {
  color: #13855c!important
}

.text-info {
  color: #36b9cc!important
}

a.text-info:focus,a.text-info:hover {
  color: #258391!important
}

.text-warning {
  color: #f6c23e!important
}

a.text-warning:focus,a.text-warning:hover {
  color: #dda20a!important
}

.text-danger {
  color: #e74a3b!important
}

a.text-danger:focus,a.text-danger:hover {
  color: #be2617!important
}

.text-light {
  color: #f8f9fc!important
}

a.text-light:focus,a.text-light:hover {
  color: #c2cbe5!important
}

.text-dark {
  color: #5a5c69!important
}

a.text-dark:focus,a.text-dark:hover {
  color: #373840!important
}

.text-body {
  color: #858796!important
}

.text-muted {
  color: #858796!important
}

.text-black-50 {
  color: rgba(0,0,0,.5)!important
}

.text-white-50 {
  color: rgba(255,255,255,.5)!important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none!important
}

.text-break {
  word-break: break-word!important;
  word-wrap: break-word!important
}

.text-reset {
  color: inherit!important
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}

.scroll-to-top:focus, .scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #5a5c69;
}

.scroll-to-top i {
  font-weight: 800;
}

@-webkit-keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animated--grow-in, .sidebar .nav-item .collapse {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.bg-gradient-primary {
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #081c38 10%, #214A80 100%);
  background-size: cover;
}

.bg-gradient-secondary {
  background-color: #858796;
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover;
}

.bg-gradient-success {
  background-color: #1cc88a;
  background-image: linear-gradient(180deg, #1cc88a 10%, #13855c 100%);
  background-size: cover;
}




.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: '\f107';
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: .25rem;
  margin-top: -.25rem;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.topbar {
  height: 4.375rem;
}

.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}



.topbar .dropdown {
  position: static;
}




.sidebar {
  width: 6.5rem;
  min-height: 100vh;
}

.sidebar .nav-item {
  position: relative;
}

.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}

.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}

.sidebar .nav-item.active .nav-link {
  font-weight: 700;
}

.sidebar .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}

.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: .5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: .5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}



.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #4e73df;
  font-weight: 700;
}

.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.sidebar #sidebarToggle::after {
  font-weight: 900;
  content: '\f104';
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.1rem;
}

.sidebar #sidebarToggle:hover {
  text-decoration: none;
}

.sidebar #sidebarToggle:focus {
  outline: none;
}

.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}

.sidebar.toggled #sidebarToggle::after {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  margin-left: 0.25rem;
}

.sidebar.toggled .sidebar-card {
  display: none;
}

.sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
 
  letter-spacing: 0.05rem;
  z-index: 1;
}

.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 1rem;
}

.sidebar .sidebar-brand .sidebar-brand-text {
  display: none;
}

.sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}

.sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}



.sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}

.sidebar .sidebar-card .sidebar-card-title {
  font-weight: bold;
}

.sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .sidebar {
    width: 14rem !important;
  }
  .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
  }
  .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
  }
  .sidebar .nav-item .collapsing {
    display: block;
    transition: height 0.15s ease;
  }
  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }
  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    /* padding: 1rem; */
    width: 14rem;
  }
  .sidebar .nav-item .nav-link i {
    font-size: 0.85rem;
    margin-right: 0.25rem;
  }
  .sidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
  }
  .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
    content: '\f105';
  }
  .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 1rem;
  }
  .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
  }
  .sidebar .sidebar-heading {
    text-align: left;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }
  .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  }
  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }
  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }
  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
  }
  .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
    display: none;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 1rem;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
  }
  .sidebar.toggled .sidebar-heading {
    text-align: center;
  }
}
.navbar-nav {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}


.btn-colour-1 {
  color: #fff;
  background-color: #004E64;
  border-color: #004E64;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 0;

}

.btn-colour-1:hover,
.btn-colour-1:active,
.btn-colour-1:focus,
.btn-colour-1.active {
  /* let's darken #004E64 a bit for hover effect */
  background: #003D4F;
  color: #ffffff;
  border-color: #003D4F;
}

.btn-colour-2 {
  color: #fff;
  background-color: #15699e;
  border-color: #15699e;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 0;

}

.btn-colour-2:hover,
.btn-colour-2:active,
.btn-colour-2:focus,
.btn-colour-2.active {
  /* let's darken #004E64 a bit for hover effect */
  background: #09344f;
  color: #ffffff;
  border-color: #09344f;
}
.i_alert{
  font-size: 10px;
}























