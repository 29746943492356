.xrayImage {
    width: 360px;
    max-width: 500px;
    height: 460px;
    max-height: 700px;
    border: solid 3px #AAB8BE;
    padding: 1px;
    position: relative;
  }
  .xrayImage img{
    width: 350px;
    max-width: 500px;
    height: 450px;
    max-height: 700px;
   
  }

  .xrayImageReport {
    width: 317px;
    max-width: 318px;
    height: 451px;
    max-height: 454px;
    border: solid 3px #AAB8BE;
    padding: 1px;
    position: relative;
  }
  .xrayImageReport img{
    width: 260px;
    max-width: 300px;
    height: 300px;
    max-height: 400px;
   
  }


  
  .xrayImage.scanning::after {
    content: '';
    left: 0;
    position: absolute;
    width: 356px;
    height: 48px;
    background-image: linear-gradient(to bottom,
      rgba(212, 221, 225, 0),
      rgba(34, 105, 204, 0.8));
    animation: scanning 2.8s linear infinite;
  }
  
  @keyframes scanning {
      100% { transform: translate(0, 400px); }
  }

  .xray-btn {
    background-color: #da2121;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
   padding: 6px;
  }

  .tb-btn{
    color: #002bff !important;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 10px;
    font-weight: bolder;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;

  }
/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* CSS */
.button-50 {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}


.glyphicon-envelope {
	text-align: center;
	line-height: 26px;
	margin-right: 5px;
	position: relative
}

.marker{
 margin-top: 2%;
 color: #161515;
}


.prediction-custom {
    margin-bottom: 20px;
    padding-left:0px;
    padding-right:0px;
    padding-bottom:30px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.prediction-custom .toolbar-custom{
	font-size: small;
}

.pred-container-custom {
    margin-bottom: 20px;
}

.pred-container-custom > div {
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}


.predbox-custom {
	max-width: 400px;
}

.row-custom {
  display: table-row;
}
.cell-custom {
  display: table-cell;
  padding-right: 20px;
}

#file-container-custom {
  margin-bottom: 20px;
}
  
.nopadding-custom {
   padding: 0 !important;
   margin: 0 !important;
}


.gradient-custom {
  background-image:
	linear-gradient(to right,green,white,#c12e2a);
}

.health-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.health-status span {
  margin: 0;
}


.explain-btn-custom{
	font-size: xx-small;
    height: 15pt;
    margin: auto;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.topbar-custom {
	background-color:lightgray;
	margin-bottom: 10px
}

.viewbox-custom {
	position:relative;
	border-color:black;
	border-width: 2px; 
	padding:0px;
	height:80%;
    min-height: 400px;
	
}

.viewbox-custom .layer-custom {
	height:100%;
	max-height: 700px;
	position:absolute;
	top:0px;
	left:0px;
	right: 0px;
	margin:auto;
	image-rendering:pixelated;
}

.status-custom{
	margin-bottom: 10px;
	left:0px;right:0px;
	margin-left:auto;margin-right:auto;
}


@keyframes blur1 {
  0% {
    filter: blur(0.89rem);
  }
  50% {
    filter: blur(0.69rem);
  }
  100% {
    filter: blur(0.89rem);
  }
}

@keyframes blur2 {
  0% {
    filter: blur(3rem);
  }
  50% {
    filter: blur(0.59rem);
  }
  100% {
    filter: blur(3rem);
  }
}

@keyframes blur3 {
  0% {
    filter: blur(1.5rem);
  }
  50% {
    filter: blur(1rem);
  }
  100% {
    filter: blur(1.5rem);
  }
}
