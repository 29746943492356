.card {
  border: 0;
  background-color: transparent;
}

.cardBody {
  background-color: transparent;
  padding: 10px;
  border: 1px solid white;
  padding-bottom: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 190px;
}

.cardTitle,
.cardText {
  background-color: transparent;
  margin: 0;
  padding: 0;
  text-align: center;
}

.cardTitle {
  font-weight: bolder;
  padding-bottom: 20px;
  padding-top: 10px;
}