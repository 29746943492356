  
  .pre_app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .background-image {
    background:center/cover no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
   
  }
  
  .logo-container {
    text-align: center;
    /* margin-top: 15%; */
  }
  
  .logo-sgpgi {
    width: 400px; /* Adjust the size as needed */
    border-radius: 10px;
    height: auto;
    animation: heartbeat 1.5s infinite; /* Heartbeat animation */
  }
  
  @keyframes heartbeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  