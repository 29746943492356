.navbar-nav a {
    color: #fff !important; /* Set the color to white */
  }
  
  /* Update the color of the navbar toggle icon */
  .navbar-toggle {
    color: #fff !important; /* Set the color to white */
  }

  .navbar-brand {
    margin-right: auto;
  }
  
  /* Center the navbar links */
  .navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  